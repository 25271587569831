import React, { useEffect, Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Header from "./Component/Home/header";
import Footer from "./Component/Home/footer";
import Booking from "./Pages/booking";
import AdminHeader from "./Pages/Admin/adminheader";
import BaseUrl from "./Api/baseurl";
import AddAppointment from "./Pages/Admin/AddDetails/addappointments";
import EditAppointment from "./Pages/Admin/EditPages/editappointment";
// import ManageSlots from "./Pages/Admin/manageslots";
import ProtectedRoute from "./Component/ProtectedRoute";
import LoaderH from "./Component/Loader/loader";
import Home from "./Pages/home";
import Userdashboard from "./Pages/User/userdashboard";

const ManageSlots = lazy(() => import("./Pages/Admin/manageslots"));
const ManageRoles = lazy(() => import("./Pages/Admin/ManageRoles/manageroles"));
const Authenticate = lazy(() =>
  import("./Component/Authenticate/authenticate")
);
const Profile = lazy(() => import("./Pages/User/userprofile"));
const EditUserProfile = lazy(() => import("./Pages/User/edituserprofile"));
const UserAppointments = lazy(() => import("./Pages/User/userappointments"));
const UserDocuments = lazy(() => import("./Pages/User/userdocuments"));
const PasswordChange = lazy(() => import("./Pages/User/passwordchange"));
const About = lazy(() => import("./Pages/about"));
const GetDetails = lazy(() => import("./Pages/getdetails"));
const ContactUs = lazy(() => import("./Pages/contactus"));
const Services = lazy(() => import("./Pages/services"));
const Blog = lazy(() => import("./Pages/blog"));
const BlogPage = lazy(() => import("./Component/Blog/blogpage"));
const DoctorProfile = lazy(() => import("./Pages/doctorprofile"));
const PrivacyPolicy = lazy(() => import("./Pages/privacypolicy"));
const TermsofService = lazy(() => import("./Pages/termsofservice"));
const Error = lazy(() => import("./Component/Home/error"));
const AdminLogin = lazy(() => import("./Pages/Admin/adminlogin"));
const DoctorLogin = lazy(() => import("./Pages/Doctor/doctorlogin"));
const DashBoard = lazy(() => import("./Pages/Admin/dashboard"));
const Appointments = lazy(() => import("./Pages/Admin/appointments"));
const MyProfile = lazy(() => import("./Pages/Admin/myprofile"));
const EditProfile = lazy(() => import("./Pages/Admin/EditPages/editprofile"));
const PatientDetails = lazy(() =>
  import("./Pages/Admin/PatientDetails/patientdetails")
);
const ChangePassword = lazy(() => import("./Pages/Admin/changepassword"));
const Notification = lazy(() => import("./Pages/Admin/notification"));
const LogoChange = lazy(() =>
  import("./Pages/Admin/Configurations/logochange")
);
const FaviconChange = lazy(() =>
  import("./Pages/Admin/Configurations/faviconchange")
);
const SocialMediaProfiles = lazy(() =>
  import("./Pages/Admin/Configurations/socialmediaprofiles")
);
const Timings = lazy(() => import("./Pages/Admin/Configurations/timings"));
const SloganText = lazy(() =>
  import("./Pages/Admin/Configurations/slogantext")
);
const Address = lazy(() => import("./Pages/Admin/Configurations/address"));
const ManagePatients = lazy(() => import("./Pages/Admin/managepatients"));
const ManageVendor = lazy(() => import("./Pages/Admin/managevendor"));
const Staff = lazy(() => import("./Pages/Admin/staff"));
const AddVendor = lazy(() => import("./Pages/Admin/AddDetails/addvendor"));
const AddStaff = lazy(() => import("./Pages/Admin/AddDetails/addstaff"));
const AddPatient = lazy(() => import("./Pages/Admin/AddDetails/addpatient"));
const EditStaff = lazy(() => import("./Pages/Admin/EditPages/editstaff"));
const EditPatient = lazy(() => import("./Pages/Admin/EditPages/editpatient"));
const ConsultationQuery = lazy(() => import("./Pages/Admin/consultationquery"));
const ManageContent = lazy(() => import("./Pages/Admin/managecontent"));
const EditContent = lazy(() => import("./Pages/Admin/EditPages/editcontent"));
const Blogs = lazy(() => import("./Pages/Admin/blogs"));
const EditVendor = lazy(() => import("./Pages/Admin/EditPages/editvendor"));
const EditBlog = lazy(() => import("./Pages/Admin/EditPages/editblog"));
const EditBlogCategory = lazy(() =>
  import("./Pages/Admin/EditPages/editblogcategory")
);
const AddBlog = lazy(() => import("./Pages/Admin/AddDetails/addblogs"));
const AddBlogCategory = lazy(() =>
  import("./Pages/Admin/AddDetails/addblogcategory")
);
const AdminServices = lazy(() => import("./Pages/Admin/adminservices"));
const AddService = lazy(() => import("./Pages/Admin/AddDetails/addservices"));
const EditService = lazy(() => import("./Pages/Admin/EditPages/editservices"));
const ManageDepartment = lazy(() => import("./Pages/Admin/managedepartment"));
const EditDepartment = lazy(() =>
  import("./Pages/Admin/EditPages/editdepartment")
);
const AddDepartment = lazy(() =>
  import("./Pages/Admin/AddDetails/adddepartment")
);
const ManageBlogCategories = lazy(() =>
  import("./Pages/Admin/manageblogcategories")
);
const ManageLocation = lazy(() => import("./Pages/Admin/managelocations"));
const AddLocation = lazy(() => import("./Pages/Admin/AddDetails/addlocation"));
const EditLocation = lazy(() => import("./Pages/Admin/EditPages/editlocation"));
const ManageContact = lazy(() => import("./Pages/Admin/managecontact"));
const ManageFeedback = lazy(() => import("./Pages/Admin/managefeedback"));
const SlotSettings = lazy(() => import("./Pages/Admin/EditPages/slotsettings"));
const ManageHolidays = lazy(() => import("./Pages/Admin/manageholidays"));
const UserLogin = lazy(() => import("./Pages/User/userlogin"));
const UserRegister = lazy(() => import("./Pages/User/userregister"));
const VendorLogin = lazy(() => import("./Pages/Vendor/vendorlogin"));
const SetupNotification = lazy(() =>
  import("./Pages/Admin/SetUpNotifications")
);

const App = () => {
  const location = useLocation();
  useEffect(() => {
    const url = window.location.href;
    const baseApi = window.location.origin;
    console.log(url, baseApi, "hi");
    if (url.includes("/authenticate/user")) {
      const username = url.split("/authenticate/user/")[1];
      console.log(username, "username");
      handleMailURL(username);
    }
  }, []);

  const handleMailURL = async (username) => {
    let url = `clinic/patient-activation/${username}/`;
    try {
      const response = await axios.put(`${BaseUrl}${url}`, {
        username: username,
      });

      if (response.data.http_status_code === 200) {
        Swal.fire({
          title: "Authenticated",
          icon: "success",
          text: "Account Activated Successfully!",
          confirmButtonText: "Close",
        });
        const { is_staff, is_vendor, is_superuser } = response.data;
        let redirectURL = "/user/login";

        if (is_staff && !is_superuser && !is_vendor) {
          redirectURL = "/doctor/login";
        } else if (is_vendor && !is_superuser && !is_staff) {
          redirectURL = "/vendor/login";
        } else {
          redirectURL = "/user/login";
        }

        setTimeout(() => {
          window.location.href = redirectURL;
        }, 2000);
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "This account dosen't exist",
        icon: "error",
        confirmButtonText: "Close",
      });

      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isAdminPath = location.pathname.startsWith("/admin");
  const adminLogin = location.pathname.endsWith("/admin/login");
  const doctorLogin = location.pathname.endsWith("/doctor/login");
  const isDoctorPath = location.pathname.startsWith("/doctor");
  const isVendorPath = location.pathname.startsWith("/vendor");
  const vendorLogin = location.pathname.endsWith("/vendor/login");
  return (
    <>
      {adminLogin ? (
        <Suspense fallback={<LoaderH />}>
          <Routes>
            <Route path="/admin/login" element={<AdminLogin />} />
          </Routes>
        </Suspense>
      ) : null}

      {doctorLogin ? (
        <Suspense fallback={<LoaderH />}>
          <Routes>
            <Route path="/doctor/login" element={<DoctorLogin />} />
          </Routes>
        </Suspense>
      ) : null}

      {vendorLogin ? (
        <Suspense fallback={<LoaderH />}>
          <Routes>
            <Route path="/vendor/login" element={<VendorLogin />} />
          </Routes>
        </Suspense>
      ) : null}

      {isDoctorPath &&
      !adminLogin &&
      !doctorLogin &&
      !isAdminPath &&
      !vendorLogin &&
      !isVendorPath ? (
        <div className="flex flex-col md:flex-row w-full">
          <AdminHeader />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/doctor" element={<ProtectedRoute />}>
                <Route path="/doctor" element={<DashBoard />} />
                <Route path="/doctor/logochange" element={<LogoChange />} />
                <Route
                  path="/doctor/faviconchange"
                  element={<FaviconChange />}
                />
                <Route
                  path="/doctor/socialmediaprofiles"
                  element={<SocialMediaProfiles />}
                />
                <Route path="/doctor/timings" element={<Timings />} />
                <Route path="/doctor/slogantext" element={<SloganText />} />
                <Route path="/doctor/address" element={<Address />} />
                <Route path="/doctor/staff" element={<Staff />} />
                <Route
                  path="/doctor/managepatients"
                  element={<ManagePatients />}
                />
                <Route path="/doctor/manageslots" element={<ManageSlots />} />
                <Route
                  path="/doctor/blogcategories"
                  element={<ManageBlogCategories />}
                />
                <Route
                  path="/doctor/manageholidays"
                  element={<ManageHolidays />}
                />
                <Route
                  path="/doctor/managelocation"
                  element={<ManageLocation />}
                />
                <Route
                  path="/doctor/managedepartment"
                  element={<ManageDepartment />}
                />
                <Route
                  path="/doctor/staff/manageroles/:username"
                  element={<ManageRoles />}
                />
                <Route
                  path="/doctor/manageslots/settings"
                  element={<SlotSettings />}
                />
                <Route path="/doctor/appointments" element={<Appointments />} />
                <Route
                  path="/doctor/consultationquery"
                  element={<ConsultationQuery />}
                />
                <Route
                  path="/doctor/managecontent"
                  element={<ManageContent />}
                />
                <Route path="/doctor/blogs" element={<Blogs />} />
                <Route path="/doctor/services" element={<AdminServices />} />
                <Route path="/doctor/feedback" element={<ManageFeedback />} />
                <Route
                  path="/doctor/manageenquiries"
                  element={<ManageContact />}
                />
                <Route
                  path="/doctor/manageholidays"
                  element={<ManageHolidays />}
                />
                {/*Patient Details*/}
                <Route path="/doctor/appointments/patientdetails/:id" element={<PatientDetails/>}/>
                <Route
                  path="/doctor/appointments/addappointments"
                  element={<AddAppointment />}
                />
                <Route path="/doctor/staff/addstaff" element={<AddStaff />} />
                <Route path="/doctor/blogs/addblogs" element={<AddBlog />} />
                <Route
                  path="/doctor/blogcategories/addblogcategory"
                  element={<AddBlogCategory />}
                />
                <Route
                  path="/doctor/managedepartment/adddepartment"
                  element={<AddDepartment />}
                />
                <Route
                  path="/doctor/services/addservices"
                  element={<AddService />}
                />
                <Route
                  path="/doctor/managelocation/addlocation"
                  element={<AddLocation />}
                />
                <Route
                  path="/doctor/services/editservices/:id"
                  element={<EditService />}
                />
                <Route
                  path="/doctor/appointments/editappointments/:id"
                  element={<EditAppointment />}
                />
                <Route
                  path="/doctor/staff/editstaff/:id"
                  element={<EditStaff />}
                />
                <Route
                  path="/doctor/managepatients/editpatient/:id"
                  element={<EditPatient />}
                />
                <Route
                  path="/doctor/managepatients/addpatient"
                  element={<AddPatient />}
                />

                <Route
                  path="/doctor/managecontent/editcontent/:slug"
                  element={<EditContent />}
                />
                <Route
                  path="/doctor/blogs/editblogs/:id"
                  element={<EditBlog />}
                />
                <Route
                  path="/doctor/blogcategories/editblogcategory/:id"
                  element={<EditBlogCategory />}
                />

                <Route
                  path="/doctor/managedepartment/editdepartment/:id"
                  element={<EditDepartment />}
                />
                <Route
                  path="/doctor/managelocation/editlocation/:id"
                  element={<EditLocation />}
                />
                <Route path="/doctor/myprofile" element={<MyProfile />} />
                <Route
                  path="/doctor/myprofile/editprofile"
                  element={<EditProfile />}
                />
                <Route
                  path="/doctor/changepassword"
                  element={<ChangePassword />}
                />
                <Route path="/doctor/notification" element={<Notification />} />
              </Route>
            </Routes>
          </Suspense>
        </div>
      ) : null}

      {isVendorPath &&
      !isDoctorPath &&
      !adminLogin &&
      !doctorLogin &&
      !isAdminPath &&
      !vendorLogin ? (
        <div className="flex flex-col md:flex-row w-full">
          <AdminHeader />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/vendor" element={<ProtectedRoute />}>
                <Route path="/vendor" element={<DashBoard />} />
                <Route path="/vendor/staff" element={<Staff />} />
                <Route
                  path="/vendor/managepatients"
                  element={<ManagePatients />}
                />
                <Route path="/vendor/appointments" element={<Appointments />} />
                <Route
                  path="/vendor/consultationquery"
                  element={<ConsultationQuery />}
                />
                <Route
                  path="/vendor/managecontent"
                  element={<ManageContent />}
                />
                <Route path="/vendor/blogs" element={<Blogs />} />
                <Route
                  path="/vendor/blogcategories"
                  element={<ManageBlogCategories />}
                />
                <Route path="/vendor/services" element={<AdminServices />} />
                <Route path="/vendor/feedback" element={<ManageFeedback />} />
                <Route
                  path="/vendor/manageenquiries"
                  element={<ManageContact />}
                />
                <Route path="/vendor/manageslots" element={<ManageSlots />} />
                <Route
                  path="/vendor/manageslots/settings"
                  element={<SlotSettings />}
                />
                <Route
                  path="/vendor/manageholidays"
                  element={<ManageHolidays />}
                />
                <Route
                  path="/vendor/managelocation"
                  element={<ManageLocation />}
                />
                <Route
                  path="/vendor/managedepartment"
                  element={<ManageDepartment />}
                />
                <Route
                  path="/vendor/staff/manageroles/:username"
                  element={<ManageRoles />}
                />
                {/* Profile Pages */}
                <Route path="/vendor/myprofile" element={<MyProfile />} />
                <Route
                  path="/vendor/changepassword"
                  element={<ChangePassword />}
                />
                <Route path="/vendor/notification" element={<Notification />} />

                {/* Configuration Pages */}
                <Route path="/vendor/logochange" element={<LogoChange />} />
                <Route
                  path="/vendor/faviconchange"
                  element={<FaviconChange />}
                />
                <Route
                  path="/vendor/socialmediaprofiles"
                  element={<SocialMediaProfiles />}
                />
                <Route path="/vendor/timings" element={<Timings />} />
                <Route path="/vendor/slogantext" element={<SloganText />} />
                <Route path="/vendor/address" element={<Address />} />

                {/*Patient Details*/}
                <Route
                  path="/vendor/appointments/patientdetails/:id"
                  element={<PatientDetails />}
                />

                {/* Add Pages   */}

                <Route path="/vendor/blogs/addblogs" element={<AddBlog />} />
                <Route
                  path="/vendor/blogcategories/addblogcategory"
                  element={<AddBlogCategory />}
                />

                <Route path="/vendor/staff/addstaff" element={<AddStaff />} />
                <Route
                  path="/vendor/managepatients/addpatient"
                  element={<AddPatient />}
                />

                <Route
                  path="/vendor/services/addservices"
                  element={<AddService />}
                />
                <Route
                  path="/vendor/appointments/addappointments"
                  element={<AddAppointment />}
                />
                <Route
                  path="/vendor/managelocation/addlocation"
                  element={<AddLocation />}
                />
                <Route
                  path="/vendor/managedepartment/adddepartment"
                  element={<AddDepartment />}
                />

                {/* Edit Pages */}

                <Route
                  path="/vendor/services/editservices/:id"
                  element={<EditService />}
                />
                <Route
                  path="/vendor/staff/editstaff/:id"
                  element={<EditStaff />}
                />
                <Route
                  path="/vendor/managepatients/editpatient/:id"
                  element={<EditPatient />}
                />
                <Route
                  path="/vendor/blogs/editblogs/:id"
                  element={<EditBlog />}
                />
                <Route
                  path="/vendor/blogcategories/editblogcategory/:id"
                  element={<EditBlogCategory />}
                />
                <Route
                  path="/vendor/appointments/editappointments/:id"
                  element={<EditAppointment />}
                />
                <Route
                  path="/vendor/managelocation/editlocation/:id"
                  element={<EditLocation />}
                />
                <Route
                  path="/vendor/managedepartment/editdepartment/:id"
                  element={<EditDepartment />}
                />
                <Route
                  path="/vendor/myprofile/editprofile"
                  element={<EditProfile />}
                />
                <Route
                  path="/vendor/managecontent/editcontent/:slug"
                  element={<EditContent />}
                />
              </Route>
            </Routes>
          </Suspense>
        </div>
      ) : null}

      {isAdminPath &&
      !adminLogin &&
      !doctorLogin &&
      !isDoctorPath &&
      !vendorLogin &&
      !isVendorPath ? (
        // Admin routes
        <div className="flex flex-col md:flex-row w-full">
          <AdminHeader />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/admin" element={<ProtectedRoute />}>
                <Route path="/admin" element={<DashBoard />} />
                <Route path="/admin/vendor" element={<ManageVendor />} />
                <Route path="/admin/staff" element={<Staff />} />
                <Route
                  path="/admin/managepatients"
                  element={<ManagePatients />}
                />
                <Route path="/admin/appointments" element={<Appointments />} />
                <Route
                  path="/admin/consultationquery"
                  element={<ConsultationQuery />}
                />
                <Route
                  path="/admin/managecontent"
                  element={<ManageContent />}
                />
                <Route path="/admin/blogs" element={<Blogs />} />
                <Route
                  path="/admin/blogcategories"
                  element={<ManageBlogCategories />}
                />
                <Route path="/admin/services" element={<AdminServices />} />
                <Route path="/admin/feedback" element={<ManageFeedback />} />
                <Route
                  path="/admin/staff/manageroles/:username"
                  element={<ManageRoles />}
                />
                <Route
                  path="/admin/vendor/manageroles/:username"
                  element={<ManageRoles />}
                />
                <Route
                  path="/admin/manageenquiries"
                  element={<ManageContact />}
                />
                <Route path="/admin/manageslots" element={<ManageSlots />} />
                <Route
                  path="/admin/managelocation"
                  element={<ManageLocation />}
                />
                <Route
                  path="/admin/managedepartment"
                  element={<ManageDepartment />}
                />
                {/* Profile Pages */}
                <Route path="/admin/myprofile" element={<MyProfile />} />
                <Route
                  path="/admin/changepassword"
                  element={<ChangePassword />}
                />
                <Route path="/admin/notification" element={<Notification />} />

                {/* Configuration Pages */}
                <Route path="/admin/logochange" element={<LogoChange />} />
                <Route
                  path="/admin/faviconchange"
                  element={<FaviconChange />}
                />
                <Route
                  path="/admin/socialmediaprofiles"
                  element={<SocialMediaProfiles />}
                />
                <Route path="/admin/timings" element={<Timings />} />
                <Route path="/admin/slogantext" element={<SloganText />} />
                <Route path="/admin/address" element={<Address />} />

                {/* Add Pages   */}

                <Route path="/admin/blogs/addblogs" element={<AddBlog />} />
                <Route
                  path="/admin/blogcategories/addblogcategory"
                  element={<AddBlogCategory />}
                />

                <Route path="/admin/vendor/addvendor" element={<AddVendor />} />
                <Route path="/admin/staff/addstaff" element={<AddStaff />} />
                <Route
                  path="/admin/managepatients/addpatient"
                  element={<AddPatient />}
                />
                <Route
                  path="/admin/services/addservices"
                  element={<AddService />}
                />
                <Route
                  path="/admin/appointments/addappointments"
                  element={<AddAppointment />}
                />
                <Route
                  path="/admin/managelocation/addlocation"
                  element={<AddLocation />}
                />
                <Route
                  path="/admin/managedepartment/adddepartment"
                  element={<AddDepartment />}
                />
                {/*Patient Details*/}
                <Route
                  path="/admin/appointments/patientdetails/:id"
                  element={<PatientDetails />}
                />
                {/* Edit Pages */}
                <Route
                  path="/admin/vendor/editvendor/:id"
                  element={<EditVendor />}
                />
                <Route
                  path="/admin/services/editservices/:id"
                  element={<EditService />}
                />
                <Route
                  path="/admin/staff/editstaff/:id"
                  element={<EditStaff />}
                />
                <Route
                  path="/admin/managepatients/editpatient/:id"
                  element={<EditPatient />}
                />
                <Route
                  path="/admin/blogs/editblogs/:id"
                  element={<EditBlog />}
                />
                <Route
                  path="/admin/blogcategories/editblogcategory/:id"
                  element={<EditBlogCategory />}
                />
                <Route
                  path="/admin/appointments/editappointments/:id"
                  element={<EditAppointment />}
                />
                <Route
                  path="/admin/managelocation/editlocation/:id"
                  element={<EditLocation />}
                />
                <Route
                  path="/admin/managedepartment/editdepartment/:id"
                  element={<EditDepartment />}
                />
                <Route
                  path="/admin/myprofile/editprofile"
                  element={<EditProfile />}
                />
                <Route
                  path="/admin/managecontent/editcontent/:slug"
                  element={<EditContent />}
                />
                <Route
                  path="/admin/setupnotification"
                  element={<SetupNotification />}
                />
              </Route>
            </Routes>
          </Suspense>
        </div>
      ) : !adminLogin &&
        !isAdminPath &&
        !doctorLogin &&
        !isDoctorPath &&
        !vendorLogin &&
        !isVendorPath ? (
        <>
          <Header />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route
                path="/authenticate/user/:username"
                element={<Authenticate />}
              />
              <Route path="/user/login" element={<UserLogin />} />
              <Route path="/user/register" element={<UserRegister />} />
              <Route path="/dashboard" element={<Userdashboard />} />
              <Route path="/userprofile" element={<Profile />} />
              <Route
                path="/userprofile/:username"
                element={<EditUserProfile />}
              />
              <Route path="/passwordchange" element={<PasswordChange />} />
              <Route path="/userappointments" element={<UserAppointments />} />
              <Route path="/userdocuments" element={<UserDocuments />} />

              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/profiledoctor/:id" element={<DoctorProfile />} />
              <Route path="/getdetails" element={<GetDetails />} />
              <Route path="/blogpage/:id" element={<BlogPage />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsofservice" element={<TermsofService />} />
              <Route path="*" element={<Error />} />
              <Route path="/loader" element={<LoaderH />} />
            </Routes>
          </Suspense>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default App;
